<style lang="scss">
.collapsible-enter-active,
.collapsible-leave-active {
  transition: all 0.2s ease;
}

.collapsible-enter-from,
.collapsible-leave-to {
  opacity: 0;
}
.collapsible-container {
  padding: 40px 0;
  border-bottom: 1px solid #eee;
}
</style>

<template>
  <transition name="collapsible">
    <div class="collapsible-container" :class="{ open: isVisible }" v-if="isVisible">
      <slot></slot>
    </div>
  </transition>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import { useStore } from 'vuex';
export default defineComponent({
  props: {
    link: {
      type: String,
      required: true,
    },
    open: Boolean,
  },
  setup(props) {
    const store = useStore();

    store.state.collapsiblestate[props.link] = props.open || false;

    const isVisible = computed(() => store.state.collapsiblestate[props.link]);

    return {
      props,
      isVisible,
    };
  },
});
</script>
